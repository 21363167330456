@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: black;
}

p {
  opacity: 0.6;
  color: white;
}

.btn {
  background: white;
  opacity: 0.7;
  color: black;
}

.btn:hover {
  opacity: 1;
  color: black;
}

.gradient,
.active {
  background: white;
  color: black;
}

.active {
  color: black;
  padding: 1rem;
  border-radius: 100%;
}

@layer base {
  body {
    @apply font-secondary text-lg leading-8 text-white;
  }
  .h2 {
    @apply font-primary text-[32px] mb-6 tracking-[10%] uppercase;
  }
  .h3 {
    @apply font-secondary font-semibold text-[26px] mb-6 leading-[46px];
  }
  .btn {
    @apply rounded-full font-primary text-white font-medium;
  }
  .btn-sm {
    @apply h-[48px] px-6 text-sm;
  }
  .btn-lg {
    @apply h-[56px] px-10 text-base;
  }
  .btn-link {
    @apply text-base;
  }
  .section {
    @apply py-8 lg:py-24 lg:h-screen flex items-center;
  }
}
